import { clearInterval, setInterval } from 'worker-timers';
(function() {
    var runningIntervalId = null;

    window.TimeEdTimer = function () {
        this.time = null;
        this.now = null;


        this.days = null;
        this.hours = null;
        this.minutes = null;
        this.seconds = null;
        this.distance = null;
        this.totalHours = null;

        var defaults = {
            timeSelector: ".started-task-box .task-spend-time",
            timeStr: false,
            setTotalTimeSelector: false,
            printTemplateStr: "%days% days %hours% hours %minutes% minutes %seconds% seconds",
            printTemplateStrTotalHours: "%totalHours%:%minutes%:%seconds%"
        };
        
        // Create options by extending defaults with the passed in arugments
        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = extendDefaults(defaults, arguments[0]);
        } else {
            this.options = defaults;
        }
    };

    TimeEdTimer.prototype.run = function () {

        if (!this.time) {
            getTime.call(this);
        }
        
        var t = this;

        if (runningIntervalId !== null) {
            clearInterval(runningIntervalId);
        }

        //Update the time
        this.now = getUtcDate();
        setTimeData.call(this);
        updateTimeBySelector.call(this);

        //After that we need to set interval for time updating
        runningIntervalId = setInterval(function () {
            t.now = getUtcDate();
            setTimeData.call(t);
            updateTimeBySelector.call(t);
        }, 1000);
    };

    function getUtcDate() {
        var now = new Date();
        return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    }

    function getTime() {
        if (this.options.timeStr) {
            this.time = new Date(this.options.timeStr);
            return;
        }

        var timeElement = document.querySelector(this.options.timeSelector);
        if (timeElement && timeElement.innerText) {
            this.time = new Date(timeElement.innerText);
        }
    }

    function setTimeData() {
        this.distance = this.now - this.time;
        if (this.distance < 0) {
            this.distance = 0;
        }

        // Time calculations for days, hours, minutes and seconds
        this.totalHours = Math.floor(this.distance / (1000 * 60 * 60));
        this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

        if (this.days < 10) this.days = '0' + this.days;
        if (this.hours < 10) this.hours = '0' + this.hours;
        if (this.seconds < 10) this.seconds = '0' + this.seconds;
        if (this.minutes < 10) this.minutes = '0' + this.minutes;
        if (this.totalHours < 10) this.totalHours = '0' + this.totalHours;

    }

    function updateTimeBySelector() {

        var
                finalStr = this.options.printTemplateStr,
                needle = ['days', 'totalHours', 'hours', 'minutes', 'seconds'],
                finalStrTotalHours = this.options.printTemplateStrTotalHours;

        for (let value in needle) {
            if (this.hasOwnProperty(needle[value])) {
                var regex = new RegExp("(\%" + needle[value] + "\%)", "g");
                finalStr = finalStr.replace(regex, this[needle[value]]);
                finalStrTotalHours = finalStrTotalHours.replace(regex, this[needle[value]]);
            }
        }

        if (this.options.timeSelector) {
            var timeElement = document.querySelectorAll(this.options.timeSelector);
            if (timeElement) {
                Array.prototype.forEach.call(timeElement, function(elements, index) {
                    elements.innerHTML = finalStr;
                });
            }
        }

        if (this.options.setTotalTimeSelector) {
            var setTotalTimeElement = document.querySelectorAll(this.options.setTotalTimeSelector);
            if (setTotalTimeElement) {
                Array.prototype.forEach.call(setTotalTimeElement, function (elements, index) {
                    elements.innerHTML = finalStrTotalHours;
                });
            }
        }

        if (typeof timerInTitle !== 'undefined' && timerInTitle) {
            const titleRexex = /\d{2}:\d{2}:\d{2}/g;
            if (document.title.match(titleRexex)) {
                document.title = document.title.replace(titleRexex, finalStrTotalHours);
            } else {
                document.title = finalStrTotalHours + ' - ' + document.title;
            }
        }
    }

    // Utility method to extend defaults with user options
    function extendDefaults(source, properties) {
        var property;
        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    }

    const faviconLinkElement = $('link[rel="icon"]');
    $(document).on('timer-start', function () {
        faviconLinkElement.attr('href', faviconLinkElement.attr('data-timer-start-icon'));
    });

    $(document).on('timer-stop', function () {
        faviconLinkElement.attr('href', faviconLinkElement.attr('data-timer-stop-icon'));
    });

    const $startedTaskBox = $(document).find('.started-task-box')

    if ($startedTaskBox.length) {
        $(window).on('scroll', (e) => {
            if (window.innerWidth < 769) {
                $startedTaskBox.toggleClass('scrolled', window.scrollY > 50)
            }
        })
    }
}());
